import React, { useState } from "react";
import Menu from "../components/common/Menu";
import { StaticImage, GatsbyImage } from "gatsby-plugin-image";
import { graphql, Link } from "gatsby";
import VideoModal from "../components/common/VideoModal";
import "../css/movieTheater.css";

const MovieActed = ({ data }) => {
  const movieActed = data.sanityPage.movieActedTemp;
  const seo = data.sanityPage.seo;
  const [open, setOpen] = useState(false);
  const [modalData, setModalData] = useState({
    title: "",
    url: "",
  });

  function handleClick(isModal, title, videoUrl, externalUrl) {
    if (isModal) {
      setModalData({
        title: title,
        url: videoUrl,
      });
      setOpen(true);
    } else {
      if (typeof window !== "undefined") {
        window.open(externalUrl, "_blank");
      }
    }
  }

  function onClose() {
    setOpen(false);
  }

  return (
    <div className="movietheater_page_bg_directed">
      <div className="stuff-i-acted">
        <div className="container">
          <div className="row stuff_padd">
            <div className="col-lg-3 col-md-3 col-3">
              <Link to="/movie-theater">
                <GatsbyImage
                  image={movieActed.back.asset.gatsbyImageData}
                  alt={movieActed.back.asset.altText || "back to home"}
                  className="img-fluid stuff-i-acted1"
                />
              </Link>
            </div>
            <div className="col-lg-6 col-md-6 col-6">
              <center>
                <Link to="/">
                  <GatsbyImage
                    image={movieActed.logo.asset.gatsbyImageData}
                    alt={movieActed.logo.asset.altText || "Gubler Land"}
                    className="img-fluid stuff-i-acted2"
                    loading="eager"
                    placeholder="blurred"
                    objectFit="contain"
                  />
                </Link>
              </center>
              <center>
                <Link to="/movie-theater">
                  <GatsbyImage
                    image={movieActed.movieTheater.asset.gatsbyImageData}
                    alt={
                      movieActed.movieTheater.asset.altText || "Movie Theater"
                    }
                    className="img-fluid stuff-i-acted3"
                    loading="eager"
                    placeholder="blurred"
                    objectFit="contain"
                  />
                </Link>
              </center>
              <center>
                <GatsbyImage
                  image={movieActed.actedImage.asset.gatsbyImageData}
                  alt={movieActed.actedImage.asset.altText || "Stuff I Acted"}
                  className="img-fluid stuff-i-acted4"
                  loading="eager"
                  placeholder="blurred"
                  objectFit="contain"
                />
              </center>
            </div>
            <div className="col-lg-3 col-md-3 col-3">
              {/*Burger Menu Start-*/}
              <Menu />
              {/*Burger Menu End-*/}
            </div>
          </div>
          <div className="row">
            {movieActed.moviesList.map((movie, index) => {
              if (movieActed.moviesList.length === index + 1) {
                return (
                  <div className="col-6 col-lg-4 col-md-4" key={index}>
                    <center>
                      <div
                        onClick={() =>
                          handleClick(
                            movie.isModal,
                            movie.title,
                            movie.videoUrl,
                            movie.externalUrl
                          )
                        }
                        style={{ cursor: "pointer" }}
                      >
                        <GatsbyImage
                          image={movie.image.asset.gatsbyImageData}
                          alt={movie.image.asset.altText || movie.title}
                          className="img-fluid stuff-i-acted6 stuff_padd"
                          loading="lazy"
                          objectFit="contain"
                        />
                      </div>
                    </center>
                  </div>
                );
              } else {
                return (
                  <div className="col-6 col-lg-4 col-md-4" key={index}>
                    <center>
                      <div
                        onClick={() =>
                          handleClick(
                            movie.isModal,
                            movie.title,
                            movie.videoUrl,
                            movie.externalUrl
                          )
                        }
                        style={{ cursor: "pointer" }}
                      >
                        <GatsbyImage
                          image={movie.image.asset.gatsbyImageData}
                          alt={movie.image.asset.altText || movie.title}
                          className="img-fluid stuff-i-acted6 stuff_padd"
                          loading="lazy"
                          objectFit="contain"
                        />
                      </div>
                    </center>
                  </div>
                );
              }
            })}
            <div className="col-6 col-lg-4 col-md-4 ml-auto">
              {" "}
              <div
                onClick={() =>
                  handleClick(true, movieActed.bug3Text, movieActed.bug3Url)
                }
                style={{ cursor: "pointer" }}
              >
                <GatsbyImage
                  image={movieActed.bug3Image.asset.gatsbyImageData}
                  alt={
                    movieActed.bug3Image.asset.altText || movieActed.bug3Text
                  }
                  className="img-fluid stuff-i-acted22"
                  loading="lazy"
                  objectFit="contain"
                />
              </div>
              <br />
              <div
                onClick={() =>
                  handleClick(true, movieActed.bug2Text, movieActed.bug2Url)
                }
                style={{ cursor: "pointer" }}
              >
                <GatsbyImage
                  image={movieActed.bug2Image.asset.gatsbyImageData}
                  alt={
                    movieActed.bug2Image.asset.altText || movieActed.bug2Text
                  }
                  className="img-fluid stuff-i-acted23"
                  loading="lazy"
                  objectFit="contain"
                />
              </div>
              <br />
              <div
                onClick={() =>
                  handleClick(true, movieActed.bug1Text, movieActed.bug1Url)
                }
                style={{ cursor: "pointer" }}
              >
                <GatsbyImage
                  image={movieActed.bug1Image.asset.gatsbyImageData}
                  alt={
                    movieActed.bug1Image.asset.altText || movieActed.bug1Text
                  }
                  className="img-fluid stuff-i-acted24"
                  loading="lazy"
                  objectFit="contain"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <VideoModal open={open} onClose={onClose} modalData={modalData} />
    </div>
  );
};

export const query = graphql`
  query MovieActed($slug: String!) {
    sanityPage(slug: { current: { eq: $slug } }) {
      title
      template
      slug {
        current
      }
      seo {
        title
        pagehandle
        ldSchema
        keywords
        description
      }
      movieActedTemp {
        back {
          asset {
            altText
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: WEBP
            )
          }
        }
        logo {
          asset {
            altText
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: WEBP
            )
          }
        }
        movieTheater {
          asset {
            altText
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: WEBP
            )
          }
        }
        actedImage {
          asset {
            altText
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: WEBP
            )
          }
        }
        moviesList {
          title
          image {
            asset {
              altText
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
                formats: WEBP
              )
            }
          }
          isModal
          videoUrl
          externalUrl
        }
        bug1Text
        bug1Url
        bug1Image {
          asset {
            altText
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: WEBP
            )
          }
        }
        bug2Text
        bug2Url
        bug2Image {
          asset {
            altText
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: WEBP
            )
          }
        }
        bug3Text
        bug3Url
        bug3Image {
          asset {
            altText
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: WEBP
            )
          }
        }
      }
    }
  }
`;

export default MovieActed;
